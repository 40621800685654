import React from "react"
import List from "../../components/List"
import Page from "../../components/Page"
import Link from "../../components/Link"
import Paragraph from "../../components/Paragraph"
import LegalList from "../../components/LegalList"
import Table from "../../components/Table"

const CookiePolicy = () => (
  <Page title={"Cookie policy"}>
    <LegalList
      description={
        <Paragraph>
          We use cookies on our website to distinguish you from other users of
          our website. This help us provide you with a good user experience when
          you browse our website and allows us to improve our website.
        </Paragraph>
      }
      sections={[
        {
          title: "What are cookies?",
          children: (
            <>
              <Paragraph>
                Cookies contain information that is transferred to your
                computer’s hard drive. They are small files of letters and
                numbers stored on your browser or hard drive.
              </Paragraph>
              <Paragraph>
                Below, we describe the different types and details of the
                specific cookies that we use.
              </Paragraph>
            </>
          ),
        },
        {
          title: "Strictly necessary cookies",
          children: (
            <>
              <Paragraph>
                These are cookies that are required for ChangeHub’s to operate.
                They include cookies that enable you to log into secure areas of
                the website.
              </Paragraph>
              <Table>
                <thead>
                  <tr>
                    <th>Cookie</th>
                    <th>First / Third party</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>XSRF-TOKEN</td>
                    <td>First party</td>
                    <td>
                      Security measure to prevent Cross-Site Request Forgery
                      attacks.
                    </td>
                  </tr>
                  <tr>
                    <td>laravel_token</td>
                    <td>First party</td>
                    <td>
                      Keeps you logged in and allows you to access secure areas
                      of the website.
                    </td>
                  </tr>
                  <tr>
                    <td>laravel-session</td>
                    <td>First party</td>
                    <td>
                      Keeps you logged in and allows you to access secure areas
                      of the website.
                    </td>
                  </tr>
                  <tr>
                    <td>cookie_consent</td>
                    <td>First party</td>
                    <td>Indicates you accept our cookie policy.</td>
                  </tr>
                </tbody>
              </Table>
            </>
          ),
        },
        {
          title: "Analytical/Performance cookies",
          children: (
            <>
              <Paragraph>
                These cookies allow us to recognize and count the number of
                visitors and also, see how visitors move around our website.
                This helps us to improve the way our website works, for example
                by ensuring that users easily find what they are looking for.
              </Paragraph>
              <Table>
                <thead>
                  <tr>
                    <th>Cookie</th>
                    <th>First / Third party</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>crisp-client</td>
                    <td>First party</td>
                    <td>
                      Used by our chat client, allows us to easily identify who
                      you are so we can help you more quickly.
                    </td>
                  </tr>
                  <tr>
                    <td>ajs_user_id</td>
                    <td>First party</td>
                    <td>Helps us track how you are using the website.</td>
                  </tr>
                  <tr>
                    <td>ajs_group_id</td>
                    <td>First party</td>
                    <td>Helps us track how you are using the website.</td>
                  </tr>
                  <tr>
                    <td>ajs_anonymous_id</td>
                    <td>First party</td>
                    <td>Helps us track how you are using the website.</td>
                  </tr>
                  <tr>
                    <td>_fathom</td>
                    <td>First party</td>
                    <td>Helps us track how you are using the website.</td>
                  </tr>
                </tbody>
              </Table>
            </>
          ),
        },
        {
          title: "How to control or block cookies",
          children: (
            <>
              <Paragraph>
                The cookies we use are designed to help you get the most from
                our website, but if you do not wish to receive cookies, most
                browsers allow you to change your cookie settings. Please note
                that if you choose to refuse cookies you may not be able to use
                the full functionality of our website.
              </Paragraph>
              <Paragraph>
                These settings will typically be found in the “options” or
                “preferences” menu of your browser. In order to understand these
                settings, the following links may be helpful, otherwise you
                should use the “Help” option in your browser for more details.
              </Paragraph>
              <List>
                <li>
                  <Link
                    to={
                      "https://support.microsoft.com/en-us/products/windows?os=windows-7"
                    }
                    external
                  >
                    Cookie settings in Internet Explorer
                  </Link>
                  <li>
                    <Link
                      to={
                        "https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies"
                      }
                      external
                    >
                      Cookie settings in Firefox
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        "https://support.google.com/chrome/answer/95647?hl=en"
                      }
                      external
                    >
                      Cookie settings in Chrome
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"https://support.apple.com/kb/ph21411?locale=en_GB"}
                      external
                    >
                      Cookie settings in Safari webiOS
                    </Link>
                  </li>
                </li>
              </List>
            </>
          ),
        },
      ]}
    />
  </Page>
)

export default CookiePolicy
